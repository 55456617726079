var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lightControl"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-back",on:{"click":function($event){return _vm.goback()}}},[_c('img',{attrs:{"src":"https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"}})]),_c('span',[_vm._v("灯光控制")]),(_vm.powerOn)?_c('div',{staticClass:"light-sensitive"},[(_vm.lights.lightSense === 1)?_c('div',[_c('img',{attrs:{"src":"https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/night.png","alt":""}}),_c('span',[_vm._v("晚上")])]):_c('div',[_c('img',{attrs:{"src":"https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/daytime.png"}}),_c('span',[_vm._v("白天")])])]):_vm._e()]),(_vm.lights.vin)?[(_vm.standby)?_c('div',{staticClass:"warning"},[_c('img',{attrs:{"src":"https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/warning.png"}}),_c('span',[_vm._v("当前处于待机模式，灯光控制无法操作")])]):_vm._e(),_c('div',{staticClass:"common light-card",class:{ disabled: _vm.standby }},[_c('div',{staticClass:"light-card-title"},[_vm._v("常用")]),_c('div',{staticClass:"light-card-content"},[_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.commonOFF },on:{"click":function($event){return _vm.lightClick('commonOFF')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/off-${
                _vm.commonOFF ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("关闭")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.lowBeamLamp === 1 },on:{"click":function($event){return _vm.lightClick('lowBeamLamp')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/lowBeam-${
                _vm.lights.lowBeamLamp === 1 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("近光灯")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.hightBeamLamp === 1 },on:{"click":function($event){return _vm.lightClick('hightBeamLamp')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/hightBeam-${
                _vm.lights.hightBeamLamp === 1 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("远光灯")])])])]),_c('div',{staticClass:"other light-card",class:{ disabled: _vm.standby }},[_c('div',{staticClass:"light-card-title"},[_vm._v("其他")]),_c('div',{staticClass:"light-card-content"},[_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.otherOFF },on:{"click":function($event){return _vm.lightClick('otherOFF')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/off-${
                _vm.otherOFF ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("关闭")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.clearanceLamp === 1 },on:{"click":function($event){return _vm.lightClick('clearanceLamp')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/clearance-${
                _vm.lights.clearanceLamp === 1 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("示廓灯")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.dayLamp === 1 },on:{"click":function($event){return _vm.lightClick('dayLamp')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/daylight-${
                _vm.lights.dayLamp === 1 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("昼行灯")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.fogLamp === 1 },on:{"click":function($event){return _vm.lightClick('fogLamp')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/fog-${
                _vm.lights.fogLamp === 1 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("雾灯")])])])]),(_vm.vType && ['A', 'B', 'C'].includes(_vm.vType.toUpperCase()))?_c('div',{staticClass:"atmosphere light-card",class:{ disabled: _vm.standby }},[_c('div',{staticClass:"light-card-title"},[_vm._v("氛围灯")]),_c('div',{staticClass:"light-card-content"},[_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.atmosphereOFF },on:{"click":function($event){return _vm.lightClick('atmosphereOFF')}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/off-${
                _vm.atmosphereOFF ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("关闭")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLamp === 2 },on:{"click":function($event){return _vm.lightClick('atmosphereLamp', 2)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/white-${
                _vm.lights.atmosphereLamp === 2 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("白色")])]),(['B', 'C'].includes(_vm.vType.toUpperCase()))?_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLamp === 3 },on:{"click":function($event){return _vm.lightClick('atmosphereLamp', 3)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/cyan-${
                _vm.lights.atmosphereLamp === 3 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("蓝绿色")])]):_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLamp === 3 },on:{"click":function($event){return _vm.lightClick('atmosphereLamp', 3)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/blue-${
                _vm.lights.atmosphereLamp === 3 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("蓝色")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLamp === 4 },on:{"click":function($event){return _vm.lightClick('atmosphereLamp', 4)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/red-${
                _vm.lights.atmosphereLamp === 4 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("红色")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLamp === 1 },on:{"click":function($event){return _vm.lightClick('atmosphereLamp', 1)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/colorful-${
                _vm.lights.atmosphereLamp === 1 ? 1 : 0
              }.png`,"alt":""}})]),_c('div',[_vm._v("七彩色")])])]),(['B', 'C'].includes(_vm.vType.toUpperCase()))?_c('div',{staticClass:"mode"},[([2, 3, 4].includes(_vm.lights.atmosphereLamp))?_c('div',{staticClass:"light-mode"},[_c('span',{staticClass:"line"}),_c('span',[_vm._v("模式选择")]),_c('span',{staticClass:"line"})]):_vm._e(),([2, 3, 4].includes(_vm.lights.atmosphereLamp))?_c('div',{staticClass:"light-card-content"},[_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLampMode === 0 },on:{"click":function($event){return _vm.lightClick('atmosphereLampMode', 0)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/steadyOn-${
                  _vm.lights.atmosphereLampMode === 0 ? 1 : 0
                }.png`,"alt":""}})]),_c('div',[_vm._v("常亮")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLampMode === 1 },on:{"click":function($event){return _vm.lightClick('atmosphereLampMode', 1)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/flicker-${
                  _vm.lights.atmosphereLampMode === 1 ? 1 : 0
                }.png`,"alt":""}})]),_c('div',[_vm._v("闪烁")])]),_c('div',{staticClass:"light-item"},[_c('div',{class:{ active: _vm.lights.atmosphereLampMode === 2 },on:{"click":function($event){return _vm.lightClick('atmosphereLampMode', 2)}}},[_c('img',{attrs:{"src":`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/breath-${
                  _vm.lights.atmosphereLampMode === 2 ? 1 : 0
                }.png`,"alt":""}})]),_c('div',[_vm._v("呼吸")])])]):_vm._e()]):_vm._e()]):_vm._e()]:[_c('van-skeleton',{attrs:{"title":"","row":"2"}}),_c('van-skeleton',{attrs:{"title":"","row":"3"}}),_c('van-skeleton',{attrs:{"title":"","row":"3"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }